<template>
  <div class="relative w-full h-full overflow-y-auto">
    <div
      class="bg-black/50 fixed top-20 left-64 right-0 h-full text-center p-10"
      v-if="importWeek"
    >
      <div
        class="bg-white rounded-lg p-4 max-h-full overflow-y-auto inline-block relative text-left w-1/2"
      >
        <div
          class="absolute top-2 right-2 p-2 cursor-pointer"
          @click="importWeek = false"
        >
          <font-awesome-icon icon="xmark" class="text-2xl" />
        </div>
        <span class="text-xl font-bold">Essensplan übernehmen</span><br />
        <span
          >Wähle ein Projekt und eine Woche aus, aus der der Essensplan
          übernommen werden soll. Alle bereits bestehenden Einträge für die
          aktuell konfigurierte Woche werden überschrieben.</span
        ><br />
        <select
          v-model="copyProject"
          class="mt-4"
          @change="getMealPlanToCopy()"
        >
          <option value="">Bitte Auswählen</option>
          <option
            v-for="project in projects"
            :key="project.id"
            :value="project.id"
          >
            {{ project.name }}
          </option>
        </select>
        <div class="grid grid-cols-2">
          <div>
            <label class="inline-block">Jahr</label>
            <select
              v-model="copyYear"
              class="inline-block"
              @change="getMealPlanToCopy()"
            >
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </select>
          </div>
          <div>
            <label class="inline-block">Kalenderwoche</label>
            <input
              type="number"
              min="1"
              :max="getMaxISOWeek(copyYear)"
              class="border-2 border-gray-200 px-2 py-1"
              v-model="copyFromWeek"
              @change="getMealPlanToCopy()"
            />
          </div>
        </div>
        <span
          v-if="copiedPlan == false"
          class="w-full text-center text-red-500 font-bold"
          >Für diese Woche gibt es in diesem Projekt keinen Plan</span
        >
        <div v-else-if="copiedPlan.Mittwoch" class="w-full text-right">
          <button @click="importSelectedWeek()">Plan übernehmen</button>
        </div>
      </div>
    </div>
    <div
      class="bg-black/50 fixed top-20 left-56 right-0 h-full text-center p-10"
      v-if="editedMeal"
    >
      <div
        class="bg-white rounded-lg p-4 max-h-full overflow-y-auto inline-block relative text-left"
      >
        <div
          class="absolute top-5 right-5 p-2 cursor-pointer"
          @click="editedMeal = false"
        >
          <font-awesome-icon icon="xmark" class="text-2xl" />
        </div>
        <span class="font-bold text-xl w-full text-center">Essen anlegen</span>
        <label>Hauptgericht</label>
        <textarea
          class="border-2 border-gray-200 px-2 py-1 w-96"
          v-model="editableMeal"
          placeholder="Gericht"
          @input="updateMeals()"
        ></textarea
        ><br />
        <span class="font-bold">Fleischart: </span>
        <select v-model="editableMealType" class="my-2">
          <option value="Vegetarisch">Vegetarisch</option>
          <option value="Geflügel">Geflügel</option>
          <option value="Fisch">Fisch</option>
          <option value="Rind">Rind</option>
          <option value="Schwein">Schwein</option>
          <option value="Lamm">Lamm</option>
        </select>
        <label>Variationen (mit "/" getrennt)</label>
        <input
          placeholder="Mit Vollkorn/Ohne Vollkorn"
          class="border-2 border-gray-200 px-2 py-1 w-96"
          v-model="editableVariation"
        />
        <div class="w-full mt-2">
          <button @click="saveMeal()">Speichern</button>
        </div>
        <div class="">
          <div
            v-for="meal in freemeals"
            @click="
              editableMeal = meal;
              updateMeals();
            "
            :key="meal"
            class="cursor-pointer border-2 border-gray-200 inline-block shadow-lg px-2 py-1 m-1"
          >
            {{ meal }}
          </div>
          <div
            v-for="meal in usedmeals"
            @click="
              editableMeal = meal;
              updateMeals();
            "
            :key="meal"
            class="cursor-pointer border-2 border-gray-200 bg-gray-500 inline-block shadow-lg px-2 py-1 m-1"
          >
            {{ meal }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-black/50 fixed top-20 left-56 right-0 h-full text-center p-10"
      v-if="editedDesserts"
    >
      <div
        class="bg-white rounded-lg p-4 max-h-full overflow-y-auto inline-block relative text-left"
      >
        <div
          class="absolute top-5 right-5 p-2 cursor-pointer"
          @click="editedDesserts = false"
        >
          <font-awesome-icon icon="xmark" class="text-2xl" />
        </div>
        <span class="font-bold text-xl w-full text-center"
          >Desserts anlegen</span
        >
        <label>Desserts (mit "/" getrennt)</label>
        <input
          placeholder="Eis/Waffeln/Salat"
          class="border-2 border-gray-200 px-2 py-1 w-96"
          v-model="editableDesserts"
        />
        <div class="w-full mt-2">
          <button @click="saveDesserts()">Speichern</button>
        </div>
        <div class="">
          <div
            v-for="dessert in imported.desserts"
            @click="editableDesserts = dessert"
            :key="dessert"
            class="cursor-pointer border-2 border-gray-200 inline-block shadow-lg px-2 py-1 m-1"
          >
            {{ dessert }}
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white rounded-lg py-4 pl-4 w-full">
      <span class="font-bold text-xl">Essensplanung</span>
      <label>Projekt</label>
      <select v-model="project" @change="setWeek()">
        <option value="">Bitte Auswählen</option>
        <option
          v-for="project in projects"
          :key="project.id"
          :value="project.id"
        >
          {{ project.name }}
        </option>
      </select>
      <label>Jahr</label>
      <select
        v-model="this.selectedYear"
        class="inline-block"
        @change="setWeek()"
      >
        <option value="2024">2024</option>
        <option value="2025">2025</option>
      </select>
      <label>Kalenderwoche</label>
      <input
        type="number"
        min="1"
        max="52"
        class="border-2 border-gray-200 px-2 py-1"
        v-model="selectedWeek"
        @change="setWeek()"
      />

      <div>
        <label>Essensplan importieren</label>
        <input
          type="file"
          ref="fileInput"
          @change="handleFileChange"
          class="mr-2"
        />
        <button v-if="!analyzing && uploadReady" @click="uploadPDF">
          Hochladen</button
        ><span id="loading" class="font-bold ml-4"></span>
        <br />
        <span class="font-bold"
          >Importiert: {{ imported.meals.length }} Gerichte,
          {{ imported.desserts.length }} Desserts</span
        ><br />
        <span
          class="bg-green-200 text-green-800 mt-2 font-bold inline-block px-2 py-1"
          >Mit einem Rechtsklick auf Hauptgerichte und Variationen kann der
          Standardwert ausgewählt werden.</span
        >
      </div>
    </div>
    <div
      v-for="(week, index) in weeks"
      :key="week"
      class="bg-white rounded-lg py-4 px-4 w-full mt-2"
    >
      <span class="font-bold text-xl"
        >KW {{ index == 0 ? selectedWeek : nextweek }} ({{
          getWeekDates(index)
        }})</span
      >
      <div
        v-for="day in Object.keys(week)"
        :key="day"
        class="border-b border-black"
      >
        <span class="font-bold">{{ day }}</span>
        <div class="inline-block ml-4">
          <input
            type="checkbox"
            :id="day"
            v-model="week[day].closed"
            class="inline-block small"
          /><label class="inline-block align-top" :for="day">Geschlossen</label>
        </div>
        <br />
        <div v-if="week[day].closed" class="p-2">
          <input
            v-model="week[day].closingReason"
            class="border-2 border-gray-200 px-2 py-1"
            placeholder="Schließgrund"
          />
        </div>
        <div v-if="!week[day].closed">
          <div
            class="shadow-lg p-2 rounded-xl m-2 cursor-pointer max-w-72 inline-block border-2"
            @click="editMeal(meal)"
            :class="
              week[day].defaults.main && week[day].defaults.main === meal.main
                ? 'border-green-600'
                : ''
            "
            v-for="meal in week[day].meals"
            :key="meal"
          >
            <div class="w-full h-full flex flex-row">
              <font-awesome-icon
                @contextmenu.prevent="setMain(week, day, meal)"
                v-if="meal.mainType === 'Schwein'"
                icon="piggy-bank"
                class="text-primary align-top mt-2 pr-2 text-4xl"
              />
              <font-awesome-icon
                @contextmenu.prevent="setMain(week, day, meal)"
                v-else-if="meal.mainType === 'Rind'"
                icon="cow"
                class="text-primary align-top mt-2 pr-2 text-4xl"
              />
              <font-awesome-icon
                @contextmenu.prevent="setMain(week, day, meal)"
                v-else-if="meal.mainType === 'Fisch'"
                icon="fish-fins"
                class="text-primary align-top mt-2 pr-2 text-4xl"
              />
              <font-awesome-icon
                @contextmenu.prevent="setMain(week, day, meal)"
                v-else-if="meal.mainType === 'Geflügel'"
                icon="drumstick-bite"
                class="text-primary align-top mt-2 pr-2 text-4xl"
              />
              <img
                src="@/assets/lamb.png"
                @contextmenu.prevent="setMain(week, day, meal)"
                v-else-if="meal.mainType === 'Lamm'"
                class="text-primary align-top mt-1 pr-2 w-12"
              />
              <font-awesome-icon
                @contextmenu.prevent="setMain(week, day, meal)"
                v-else-if="meal.mainType === 'Vegetarisch'"
                icon="leaf"
                class="text-primary align-top mt-2 pr-2 text-4xl"
              />
              <font-awesome-icon
                @contextmenu.prevent="setMain(week, day, meal)"
                v-else
                icon="utensils"
                class="text-primary align-top mt-2 pr-2 text-4xl"
              />
              <div class="inline-block">
                <div class="border-b border-gray-700">
                  <span v-if="meal.main == ''" class="font-bold"
                    >Kein Gericht</span
                  >
                  <span
                    v-else
                    class="font-bold"
                    @contextmenu.prevent="setMain(week, day, meal)"
                    >{{ meal.main }}</span
                  >
                </div>
                <div>
                  <span v-if="meal.variations.length == 0" class="font-bold"
                    >Keine Variationen</span
                  >
                  <div v-else class="pt-2">
                    <div
                      v-for="variation in meal.variations"
                      :key="variation"
                      @contextmenu.prevent="
                        setVariation(week, day, meal, variation)
                      "
                      :class="
                        week[day].defaults.variation == variation &&
                        week[day].defaults.main === meal.main
                          ? 'border-green-600'
                          : ''
                      "
                      class="font-bold px-2 py-1 m-1 border-2 bg-gray-100 rounded-lg inline-block"
                    >
                      {{ variation }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="inline-block shadow-lg p-2 rounded-xl m-2 cursor-pointer align-top"
          >
            <font-awesome-icon
              icon="ice-cream"
              class="text-primary align-top mt-2 text-4xl"
            />
            <div class="inline-block">
              <div @click="editDesserts(week[day])">
                <span v-if="week[day].desserts.length == 0" class="font-bold"
                  >Keine Desserts</span
                >
                <div v-else class="pt-2">
                  <div
                    v-for="dessert in week[day].desserts"
                    :key="dessert"
                    class="font-bold px-2 py-1 m-1 border-2 bg-gray-100 rounded-lg inline-block"
                    :class="
                      week[day].defaults.dessert == dessert
                        ? 'border-green-600'
                        : ''
                    "
                    @contextmenu.prevent="setDessertDefault(week, day, dessert)"
                  >
                    {{ dessert }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 w-full text-center">
        <button class="w-96 mx-2" @click="importWeekDialog(index)">
          Essensplan aus Projekt oder Woche importieren
        </button>
      </div>
      <div class="mt-4 w-full text-center">
        <button class="w-96 mx-2" @click="exportWeekByContract(index)">
          Buchungen exportieren (nach Vertrag)
        </button>
        <button class="w-96 mx-2" @click="exportWeek(index)">
          Buchungen exportieren (nach Essen)
        </button>
        <button class="w-96 mx-2 mt-2" @click="saveWeek(index)">
          Woche speichern
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
label {
  display: block;
  font-weight: bold;
}
table {
  max-width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #e6e6e6;
  /* Set the background color for even rows */
}
</style>
<script>
// @ is an alias to /src
import API from "@/API";
export default {
  name: "Mealplanning",
  data() {
    return {
      uploadReady: false,
      freemeals: [],
      usedmeals: [],
      imported: {
        meals: [],
        desserts: [],
      },
      currentYear: new Date().getFullYear(),
      selectedYear: new Date().getFullYear(),
      nextweekYear: new Date().getFullYear(),
      copyYear: new Date().getFullYear(),
      project: "",
      projects: [],
      editedDesserts: false,
      editableDesserts: "",
      editableMeal: "",
      editableMealType: "Vegetarisch",
      editableVariation: "",
      editedMeal: false,
      currentWeek: 0,
      selectedWeek: 1,
      weeks: [],
      selectedFile: null,
      analyzing: false,
      copyProject: "",
      copyFromWeek: 1,
      copiedPlan: {},
      importWeek: false,
      weekToCopyTo: {},
    };
  },

  methods: {
    importWeekDialog(week) {
      this.weekToCopyTo = week;
      this.copiedPlan = {};
      this.importWeek = true;
    },
    importSelectedWeek() {
      this.weeks[this.weekToCopyTo] = this.copiedPlan;
      this.importWeek = false;
    },
    setVariation(week, day, meal, variation) {
      if (week[day].defaults.main === meal.main) {
        week[day].defaults.variation = variation;
      }
    },
    setMain(week, day, meal) {
      console.log(meal.variations);
      week[day].defaults.main = meal.main;
      week[day].defaults.variation =
        meal.variations.length > 0 ? meal.variations[0] : "";
    },
    saveWeek(index, project = this.project) {
      let week=index==0?this.selectedWeek:this.nextweek
      let year=index==0?this.selectedYear:this.nextweekYear
      let vm = this;
      API.saveMealPlanForWeek(
        project,
        year,
        week,
        this.weeks[index],
        (r, status) => {
          if (status === 200) {
            vm.$toast.open({
              message: "Woche gespeichert!",
              type: "success",
              transition: "bounce", // all transition effect
            });
            setTimeout(() => {
              vm.$toast.clear();
            }, 3000);
          }
        }
      );
    },
    exportWeek(index) {
      let week = index == 0 ? this.selectedWeek : this.nextweek;
      let year = index == 0 ? this.currentYear : this.nextweekYear;
      const url =
        process.env.VUE_APP_BE_URL +
        `/mealplan/export/${this.project}/${year}${week}`;
      const win = window.open(url, "_blank");
      win.focus();
    },
    exportWeekByContract(index) {
      let week = index == 0 ? this.selectedWeek : this.nextweek;
      let year = index == 0 ? this.currentYear : this.nextweekYear;
      const url =
        process.env.VUE_APP_BE_URL +
        `/mealplan/export/byContract/${this.project}/${year}${week}`;
      const win = window.open(url, "_blank");
      win.focus();
    },
    getWeekDates(index) {
      const week = index === 0 ? this.selectedWeek : this.nextweek;
      const year = index === 0 ? this.selectedYear : this.nextweekYear;
      const jan1 = new Date(year, 0, 1);
      const firstDayOfWeek = (jan1.getDay() + 6) % 7; // Adjust so Monday = 0
      const daysOffset = (week - 1) * 7 - firstDayOfWeek;
      const startDate = new Date(jan1);
      startDate.setDate(jan1.getDate() + daysOffset);

      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 4); // Assuming work weeks of 5 days

      const startDateStr = startDate.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
      });
      const endDateStr = endDate.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
      });

      return `${startDateStr} - ${endDateStr}`;
    },

    updateMeals() {
      let allMeals = [];
      this.weeks.forEach((week) => {
        Object.keys(week).forEach((day) => {
          week[day].meals.forEach((meal) => {
            if (!allMeals.includes(meal.main)) {
              allMeals.push(meal.main);
            }
          });
        });
      });
      this.freemeals = this.imported.meals.filter(
        (x) => !allMeals.includes(x) && this.editableMeal != x
      );
      this.usedmeals = this.imported.meals.filter(
        (x) => !this.freemeals.includes(x)
      );
      this.freemeals = this.freemeals.filter((x) =>
        x.toLowerCase().includes(this.editableMeal.toLocaleLowerCase())
      );
      this.usedmeals = this.usedmeals.filter((x) =>
        x.toLowerCase().includes(this.editableMeal.toLocaleLowerCase())
      );
    },
    handleFileChange() {
      this.selectedFile = this.$refs.fileInput.files[0];
      this.uploadReady = true;
    },
    uploadPDF() {
      this.analyzing = true;
      let loadInterval = setInterval(() => {
        if (document.getElementById("loading")) {
          let el = document.getElementById("loading");
          if (el.innerText == "") el.innerText = "Datei wird analysiert";
          else if (el.innerText == "Datei wird analysiert")
            el.innerText = "Datei wird analysiert.";
          else if (el.innerText == "Datei wird analysiert.")
            el.innerText = "Datei wird analysiert..";
          else if (el.innerText == "Datei wird analysiert..")
            el.innerText = "Datei wird analysiert...";
          else if (el.innerText == "Datei wird analysiert...")
            el.innerText = "Datei wird analysiert";
        }
      }, 500);
      if (!this.selectedFile) return;
      API.importMealplan(this.selectedFile, (r, status) => {
        if (status === 200) {
          this.analyzing = false;
          clearInterval(loadInterval);
          document.getElementById("loading").innerText = "";
          this.imported = r;
          this.updateMeals();
        }
      });
    },
    editMeal(meal) {
      this.editedMeal = meal;
      this.editableMeal = meal.main;
      this.editableMealType = meal.mainType ?? "Vegetarisch";
      this.editableVariation = meal.variations.join("/");
      this.updateMeals();
    },
    setDessertDefault(week, day, dessert) {
      if (week[day].defaults.dessert == dessert) {
        week[day].defaults.dessert = "";
      } else {
        week[day].defaults.dessert = dessert;
      }
    },
    editDesserts(weekday) {
      this.editableDesserts = weekday.desserts.join("/");
      this.editedDesserts = weekday;
    },
    saveDesserts() {
      this.editedDesserts.desserts = this.editableDesserts
        .split("/")
        .filter((x) => x);
      console.log(this.editedDesserts);
      this.editedDesserts = false;
    },
    saveMeal() {
      this.editedMeal.main = this.editableMeal;
      this.editedMeal.mainType = this.editableMealType;
      this.editedMeal.variations = this.editableVariation
        .split("/")
        .filter((x) => x);
      this.editedMeal = false;
      this.updateMeals();
    },
    getMealPlanToCopy() {
      if (this.copyProject == "") return;
      API.getMealPlanForWeek(
        this.copyProject,
        this.copyYear,
        this.copyFromWeek,
        (r, status) => {
          if (status == 404) {
            this.copiedPlan = false;
          } else if (status == 200) {
            let empty = true;
            Object.keys(r).forEach((day) => {
              r[day].meals.forEach((meal) => {
                if (meal.main != "") empty = false;
              });
            });
            if (empty) {
              this.copiedPlan = false;
            } else {
              this.copiedPlan = r;
            }
          }
        }
      );
    },
    getWeekOfYear() {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), 0, 1);
      let days = Math.floor((date - firstDay) / (24 * 60 * 60 * 1000));
      this.currentWeek = Math.ceil((days + firstDay.getDay() + 1) / 7);
      this.copyFromWeek = this.currentWeek;
    },
    getMaxISOWeek(year) {
      const lastDayOfYear = new Date(year, 11, 31); // December 31st
      const dayOfWeek = lastDayOfYear.getDay() || 7; // Ensure Sunday is 7
      const lastISOWeek = Math.ceil(
        (lastDayOfYear.getDate() - dayOfWeek + 10) / 7
      );
      return lastISOWeek === 53 ? 53 : 52; // Adjust if the year ends with week 53
    },
    setWeek() {
      this.nextweek =
        this.selectedWeek == this.getMaxISOWeek(this.selectedYear)
          ? 1
          : this.selectedWeek + 1;
      if (this.nextweek == 1) {
        this.nextweekYear = this.selectedYear + 1;
      }
      else{
        this.nextweekYear = this.selectedYear
      }
      if (this.project == "") return;
      this.weeks = [];
      let vm = this;
      API.getMealPlanForWeek(
        this.project,
        this.selectedYear,
        this.selectedWeek,
        (r, status) => {
          if (status === 200) {
            vm.weeks.push(r);
            API.getMealPlanForWeek(
              vm.project,
              vm.nextweekYear,
              vm.nextweek,
              (r, status) => {
                if (status === 200) {
                  vm.weeks.push(r);
                }
              }
            );
          }
        }
      );
    },
    getProjects() {
      let vm = this;
      API.getProjectsForEating((r, status) => {
        if (status === 200) {
          vm.projects = r.reverse();
        }
      });
    },
  },
  created() {
    this.getProjects();
    this.getWeekOfYear();
    this.selectedWeek = this.currentWeek;
    this.updateMeals();
    this.setWeek();
  },
};
</script>